.footer-container{
    dispplay: flex;
    justify-content: center;
    height: 17.8%;
    width: 100%;
    overflow: hidden;
}

.footer-parent{
    width: 100%;
    height: 100%;
    max-width: 4000px
}

.footer-parent img{
    width: 100%;
    height: 130px;
}


@media screen and (max-width: 1110px) {
    .footer-parent{
        display: flex;
        align-items: flex-end;
    }
}