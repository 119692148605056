.about-me-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
}
.about-me-parent{
    max-width: 1000px;
    width: 70%;
}

.about-me-card{
    display: flex;
    width: 100%;
    box-shadow: 0 0 20px -2px #1f2235;
}

.about-me-profile{
    width: 50%;
    background-image: url('../../assets/AboutMe/me.JPEG');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.about-me-details{
    width: 100%;
    text-align: justify;
    padding: 30px;
}

.about-me-description{
    font-size: 12px;
}

.about-me-highlights{
    padding: 30px;
}

.highlight-heading{
    font-size: 14px;
    font-family: "Poppins Bold";
    margin: 0 0 5px 0;
}

.highlight{
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 12px;
}

.highlight-blob{
    height: 12px;
    width: 12px;
    margin: 0 10px 0 0 ;
    background-color: #f5695b;
    border-radius: 50%;
}

.about-me-options {
    display: flex;
    justify-content: space-around;
    margin: 30px 0 0 0;
}

.about-me-texts{
    width: 48%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1110px){
    .about-me-parent{
        width: 88%
    }
}

@media screen and (max-width: 898px) {
    .about-me-profile{
        display: none;
    }
    .about-me-texts{
        width: 100%;
    }
}
@media screen and (max-width: 350px) {
    .about-me-options{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
