:root {
    --light-blue: #04C3FF;
    --light-blue-shadow: rgb(69 211 255 / 27%);
    --sea-green: #0ABEAD;
    --sea-green-dark: #0CBFAE;
    --sea-green-extra-dark: #09BEAD;
    --sea-green-shadow: rgb(11, 190, 173, 0.8);
    --header-hamburger-background: rgba(11, 190, 173, 0.8);
    --orange: #F57F17;
    --dark-orange: #FF5823;
    --orange-shadow: rgba(245, 127, 23, 0.2);
    --white: #ffffff;
    --black: #333333;
    --disabled-btn: #09BEAD7A;
    --error-message: #E44F4Fd1;
    --toast-error: #E44F4FFA;
    --toast-shadow: #9C9C9CE3;
    --toast-success: #0CBFAE;
    --toast-warning: #F57F17;
    --loader-color: #0CBFAE;
}