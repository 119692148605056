.heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 0 50px 0;
    text-align: center;
}

.screen-sub-heading {
    letter-spacing: 3px;
    margin: 8px 0 18px 0;
    font-size: 12px;
    color: var(--black);
}

.screen-heading {
    font-size: 32px;
    font-family: "Poppins Bold";
    color: #1f2235;
}

.heading-separator {
   display: flex;
    align-items: center;
    position: relative;
    margin: 10px 0 0 0;
    width: 180px;
}

.separator-line{
    width: 100%;
    height: 2px;
    background-color: #1f2235;
}

.separator-blob{
    height: 10px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: -0.3rem;
}
.separator-blob div{
    width: 35px;
    border-radius: 10px;
    background-color: #f5695b;
}
