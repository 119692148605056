@font-face {
    font-family: "Poppins Medium";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins SemiBold";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins Bold";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: "Poppins ExtraBold";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-ExtraBold.ttf");
}
@font-face {
    font-family: "Poppins Light";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-Light.ttf");
}
@font-face {
    font-family: "Poppins Thin";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Poppins-Thin.ttf");
}

* {
    outline: none;
}

body {
    margin: 0;
    overflow-y: visible;
    font-family: "Poppins Regular";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

body ::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 100%, 0.16);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
    background: hsla(0, 0%, 100%, 0.1);
}

.btn {
    border-radius: 50px;
    padding: 14px 0;
    width: 160px;
    cursor: pointer;
    transition: 0.2s;
    font-family: "Poppins SemiBold";
}
.btn:active{
    transform: translateY(2px);
}

.primary-btn {
    color: var(--white);
    border: 2px solid linen;
    font-size: 12px;
    background-color: #1f2235;
}

@media only screen and (max-width: 414px) {
    .primary-btn {
        font-size: 13px;
    }
}

.primary-btn:hover {
    background-color: #1f2235;
    border: 2px solid #ff5823;
    color: aliceblue;
}

.highlighted-btn {
    color: var(--white);
    font-size: 12px;
    background-color: #ff5823;
}

@media only screen and (max-width: 568px) {
    .highlighted-btn {
        z-index: -900;
    }
}

.red-border {
    border: 2px solid var(--error-message) !important;
}

.fade-in {
    opacity: 0;
    transform: translateY(80px);
    transition: all 0.8s ease;
}


